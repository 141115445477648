.flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F8F8F8;

    .cardBackground {
        width: 330px;
        background-color: #8BC846;
        border-radius: 8px;

        .loginCard {
            align-items: center;
            border-radius: 10px;
            margin-top: 10px;
        }
    }
}

.flexContainer>.cardBackground>.loginCard>.cardBody {
    width: 330px;
    align-items: center;
    box-shadow: -2px 1px 13px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    .image {
        border-radius: 5%;
        border-color: #fff;
        border-width: 15px;
        margin-top: -78px;
        text-align: center !important;

        .img {
            border-radius: 50%;
            border: 7px solid #fff;
            background-color: #fff
        }
    }

    .btn {
        background-color: #8BC83F;
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
        font-size: 18px;
        font-weight: bolder;
        border: 0
    }

    .h1 {
        color: #8BC846;
        font-size: 30px;
        margin-bottom: 1rem;
    }
}

.formCheck {
    float: left;
    margin-right: 0px;
    margin-top: 0.5rem;
}

.formCheckLabel {
    font-size: 15px;
    color: #8BC846;
    padding-left: 0;
    margin-left: 0
}

form .input {
    height: 40px;
    background-color: #F4F5F9;
    border: none
}

.icon {
    font-size: 17px
}

.visibilityIcon {
    position: absolute;
    bottom: 10px;
    right: 0;
    color: rgb(196, 189, 189);
    font-size: 20px;
    text-decoration: none;
    top: 2px;
    background: none;
    border: none
}

.errorMessage {
    color: #ed4956;
    font-size: 13px;
    margin-top: -5px
}

a.loginScreenStyle_formCheckLabel__pyCrH :hover{
    color: #8BC846 !important;
}