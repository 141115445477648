
/*style for table*/

/*.container {*/
/*    margin: 48px 3% 2% 3% ;*/
/*}*/

/*.car{*/
/*    background: #ffffff ;*/
/*    box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px;*/
/*    margin: 0 0 1rem 0;*/
/*}*/

/*.breadcrumb{*/
/*    font-size: 14px;*/
/*    border-bottom: 1px solid #dddddd;*/
/*    padding:12px 0 12px 2.5% ;*/
/*    color: #8BC83F;*/
/*    font-weight:bold;*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    background: white;*/
/*    z-index: 1;*/
/*}*/

/*.link {*/
/*    color: #8BC83F;*/
/*}*/

/*.inputField {*/
/*    border: 0;*/
/*    background-color: #F4F5F9;*/
/*    color: rgb(130, 132, 129);*/
/*    font-size: 14px;*/
/*    height: 40px;*/
/*    width: 100%;*/
/*    padding-top: 0px;*/
/*    margin-top: 16px;*/
/*    padding-left: 15px;*/
/*}*/
/*.columnHeading{*/
/*    color: #625F5F;*/
/*    margin-bottom: 0px;*/
/*    font-size: 16px;*/
/*    width: 100%;*/
/*    text-align: left !important;*/
/*}*/

/*.clientData {*/
/*    color: #625F5F;*/
/*    margin-bottom: 0px;*/
/*    font-size: 16px;*/
/*    width: 100%;*/
/*    text-align: left !important;*/
/*    margin-top: 10px;*/
/*    font-weight: 550;*/
/*}*/

/*.expandMoreIcon {*/
/*    position: 'relative',*/
/*    right: '-88%',*/
/*    top: '-32px',*/
/*    color: '#C8C5C5',*/
/*    fontSize: '35px',*/
/*}*/

/*.deleteClientButton {*/
/*    background: #8BC83F;*/
/*    color: white;*/
/*    width: 100%;*/
/*    padding: 0.6rem;*/
/*}*/

/*.tableHeader {*/
/*    color: #8BC83F;*/
/*    font-weight: bold;*/
/*    font-size: 15px;*/
/*    text-align: left;*/
/*    height: 3rem;*/
/*    top: 0;*/
/*    position: inherit;*/
/*}*/

/*.tableBody {*/
/*    font-size: 15px;*/
/*    color: #626262;*/
/*    text-align: left;*/
/*    height: 4.5rem;*/
/*}*/

/*.flex {*/
/*    padding-bottom: 1rem;*/
/*    padding-top: 1rem;*/
/*    padding-left: 1rem;*/
/*}*/

/*.settingButton {*/
/*    border: none;*/
/*    background: #8BC83F;*/
/*    color: white;*/
/*    padding: 4px;*/
/*    font-size: 16px;*/
/*    border-radius: 2px;*/
/*    margin-left: 1rem;*/
/*    width: 35px;*/
/*    height: 35px;*/
/*}*/

/*.addButton {*/
/*    border: none;*/
/*    background: #8BC83F;*/
/*    color: white;*/
/*    width: 160px;*/
/*    height: 35px;*/
/*    padding: 3px 14px 3px 10px;*/
/*    font-size: 16px;*/
/*    border-radius: 2px;*/
/*}*/

/*.avatarName {*/
/*    font-size: 12px;*/
/*    padding: 0.8rem;*/
/*    color: #626262;*/
/*    margin-left: 2rem;*/
/*}*/
/*.progressBar {*/
/*    margin-top: 0px;*/
/*    height: 0.6rem;*/
/*}*/
/*.progressData {*/
/*    margin-bottom: 0;*/
/*    padding-bottom: 0;*/
/*}*/
/*.progressDiv {*/
/*    padding-top: 8px;*/
/*    width: 100%;*/
/*}*/

/*.deleteCaseTemplateButton {*/
/*    background: #8BC83F;*/
/*    color: white;*/
/*    width: 100%;*/
/*    padding: 0.5rem;*/
/*}*/

/*.cancelButton {*/
/*    border: 1px solid #8BC83F;*/
/*    color: #8BC83F;*/
/*    width: 100%;*/
/*    padding: 0.5rem;*/
/*}*/

/*.search{*/
/*    position: relative;*/
/*    border-radius: 8px;*/
/*    background-color: #F4F5F9;*/
/*    margin-right: 1.2rem;*/
/*    width: 270px;*/
/*}*/
/*.searchIcon{*/
/*    width: 7px;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    pointer-events: none;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-right: 2rem !important;*/
/*    margin-left: 1rem;*/
/*}*/

/*.inputBase {*/
/*    width: 270px;*/
/*    !*height: 26px;*!*/
/*}*/

/*****ant design css******/

/*.ant-tag-blue {*/
/*    border-color:#e6f7ff !important;*/
/*}*/
/*.ant-tag-orange {*/
/*    border-color: #fff7e6 !important;*/
/*}*/
/*.ant-tag-red {*/
/*    border-color: #fff1f0 !important;*/
/*}*/
/*.ant-tag-purple {*/
/*    border-color: #f9f0ff !important;*/
/*}*/
/*.ant-tag-green {*/
/*    border-color: #f6ffed !important;*/
/*}*/

/*.ant-select{*/
/*    width: 100% !important;*/
/*}*/
/*.ant-select-single.ant-select-show-arrow .ant-select-selection-item {*/
/*    padding-right: 215px !important;*/

/*}*/
/*.select-input input:focus, .select-input input:active*/
/*{*/
/*    outline: none !important;*/
/*    border: none !important;*/
/*}*/

/*.select-input .ant-picker:hover,*/
/*.select-input .ant-picker:focus,*/
/*.select-input .ant-picker:active*/
/*{*/
/*    border-color: #8bc83f !important;*/
/*}*/

/*.select-input .ant-picker-clear{*/
/*    display: none !important;*/
/*}*/

/*.select-input .ant-picker-range{*/
/*    height: 40px !important;*/
/*}*/
/*.select-input .ant-picker-focused{*/
/*    border-color: #8bc83f !important;*/
/*    box-shadow: none !important;*/
/*}*/
/*.select-input .ant-picker-range .ant-picker-active-bar{*/
/*    background-color: #8bc83f !important;*/
/*}*/
/*.select-input .ant-picker{*/
/*    width: 170px !important;*/
/*}*/
/*.select-input .ant-picker-input{*/
/*    padding-top: 5px !important;*/
/*}*/

/*.ant-select-selector{*/
/*    border: 0px !important;*/
/*    background-color: rgb(244, 245, 249) !important;*/
/*    color: rgb(130, 132, 129) !important;*/
/*    height: 40px !important;*/
/*    width: 100% !important;*/
/*    padding-top: 5px !important;*/
/*    margin-top: 4px !important;*/
/*    padding-left: 15px !important;*/

/*}*/

/*.ant-select-selector:focus{*/
/*    outline: 2px solid #8bc83f !important;*/
/*}*/

/*.ant-select-arrow{*/
/*    padding-top: 8px !important;*/
/*}*/

/*.ant-select-selection-search-input{*/
/*    padding-top: 10px !important;*/
/*}*/

/*input::-webkit-outer-spin-button,*/
/*input::-webkit-inner-spin-button {*/
/*    -webkit-appearance: none;*/
/*    margin: 0;*/
/*}*/

/*.ant-tag-blue,*/
/*.ant-tag-red,*/
/*.ant-tag-purple,*/
/*.ant-tag-green{*/
/*    width: 182px !important;*/
/*    text-align: center !important;*/
/*}*/

/*.icon{*/
/*    position: absolute;*/
/*    top: 56px;*/
/*    right: 30px;*/
/*    color: rgb(200, 197, 197);*/
/*    font-size: 32px;*/
/*}*/

/*.addUserButton{*/
/*    background: #8BC83F;*/
/*    color: white;*/
/*    width: 100%;*/
/*    padding: 0.5rem;*/
/*    border: 1px solid #8BC83F;*/
/*}*/


/*.view-documents{*/
/*    font-size: 20px;*/
/*    margin-top: 10px !important;*/
/*    color: gray;*/
/*    float: right;*/
/*    cursor: pointer;*/
/*}*/