.rbc-header span{
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.rbc-time-view .rbc-row {
    min-height: 39px !important;
}
.rbc-event {
    /*background-color: #98ca69 !important;*/
}
.rbc-event-label {
    font-size: 85%;
}
.rbc-show-more{
    color: dimgray;
}
.rbc-btn-group button:hover{
    background: #ffffff;
    color: #90c44f;
    border-color:  #90c44f;
}
.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #eaf6fe;
    pointer-events: none;
}


.rbc-toolbar button:focus,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button.rbc-active {
    background: #fff !important;
    color: #90c44f;
    box-shadow: none;
    border-color:  #90c44f;

}
.rbc-row-segment{
    padding-right: 22px;
}

.columnHeading {
    color: #625F5F;
    margin-bottom: 0px;
    font-size: 16px;
    width: 100%;
    text-align: left !important;
}

.extraData {
    color: #625F5F;
    margin-bottom: 0px;
    font-size: 16px;
    text-align: left !important;
    margin-top: 10px;
    font-weight: 550;
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.data {
    color: #625F5F;
    margin-bottom: 0px;
    font-size: 16px;
    width: 100%;
    text-align: left !important;
    margin-top: 10px;
    font-weight: 550;
}

.span-row{
    padding: 0.4rem 0px 0.5rem;
    width: 700px;
}
.cancelButton {
    border: 1px solid #8BC83F;
    color: #8BC83F;
    width: 100%;
    padding: 20px;
}

.rbc-row-content{
    padding-top: 12px;
}
.calendar-wrapper{
    padding: 14px 50px 30px;
    height: 80%;

}
/*.rbc-calendar{*/
/*    height: 105% !important;*/
/*}*/

.client-calendar .rbc-calendar{
    height: 380px;
}

.search-block{
    display: flex;
    justify-content: flex-end;
    padding-top: 63px;
    padding-right: 35px;
}
.cancelButton {
    border: 1px solid #8BC83F;
    color: #8BC83F;
    width: 100%;
    padding: 0.5rem;
}
.searchIcon {
    width: 7px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem !important;
    margin-left: 1rem;
}
.search {
    position: relative;
    border-radius: 8px;
    background-color: #F4F5F9;
    margin-right: 1.2rem;
    width: 270px;
    height: 40px;
}
.inputBase {
    width: 270px;
}

.rbc-day-slot .rbc-event {
  border: none;
  min-height: 4%;
  width: 104%;
}

/*.rbc-agenda-view table.rbc-agenda-table > tr{*/
/*    background-color: #ffffff !important;*/
/*}*/
