/*Loader*/
.loader {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(229, 229, 229, 0.5);
    justify-content: center;
    align-items: center;
}

.loader>img {
    width: 150px;
}

.loader.hidden {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/*login-page*/
input #email:focus {
    outline: none;
    border: 0;
}


#formCheckInput:focus {
    color: white;
    background-color: green;
}

.listHover:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.hideScrollBar::-webkit-scrollbar {
    display: none;
}

.btn:hover {
    background-color: #8BC83F;
    outline: 0;
}

.btn:focus {
    outline: 0;
    border: 0;
}

.btn:active {
    outline: none;
    background-color: #8BC83F;
}

/* input placeholder */
form input[placeholder] {
    font-size: 13px;
    padding-left: 32px;
    padding-top: 7px;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-group {
    position: relative;
}

#emailIcon {
    position: absolute;
    bottom: 10px;
    left: 6px;
    color: rgb(196, 189, 189);
    font-size: 20px;
}

#passwordIcon {
    position: absolute;
    bottom: 10px;
    left: 6px;
    color: rgb(196, 189, 189);
    font-size: 20px;
}

#formCheckInput {
    background-color: greenyellow !important;
    border-radius: 50% !important;
    color: green;
}

/*navbar*/
/*div.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters{*/
/*  padding-right: 0;*/
/*  box-shadow: none;*/
/*}*/

/*div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button{*/
/*  padding-right: 8px;*/
/*  padding-left: 25px;*/
/*}*/

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected {
    background: none;
}

svg#expandIcon.MuiSvgIcon-root.makeStyles-expandIcon-19 {
    margin-left: -27px !important;
}

/*div.MuiListItemIcon-root{*/
/*  padding-left: 0 !important;*/
/*  margin-right: 4px !important;*/
/*  min-width: 41px;*/
/*}*/

/*button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-6.MuiIconButton-colorInherit.MuiIconButton-edgeStart{*/
/*  padding-left: 0 !important;*/
/*}*/

/*button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-415.MuiIconButton-colorInherit.MuiIconButton-edgeStart{*/
/*  padding-left: 0 !important;*/
/*}*/

/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-8.makeStyles-drawerPaperClose-9.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{*/
/*  width: 65px;*/
/*}*/

/*ul.MuiList-root.MuiList-padding{*/
/*  padding-top: 0 !important;*/
/*}*/

/*.makeStyles-drawerPaper-8{*/
/*    width: 234px!important;*/
/*}*/
/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-8{*/
/*    width: 234px !important;*/
/*}*/

.jss8{
    width: 100% !important;
}
@media (min-width: 600px){
    .jss9 {
        width: 72px !important;
    }
}

.jss9 {
    width: 72px !important;
}


.makeStyles-drawerPaper-8 {
    width: 110% !important;
}

@media (min-width: 0px){
    .makeStyles-drawerPaperClose-9 {
        width: 72px !important;
    }
}

div.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected{
    border-right: 2px solid #8BC83F !important;
    /*background: #a6a6a512;*/
    background: none;
    line-height: 10px;
}

/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-213.makeStyles-drawerPaperClose-214.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{*/
/*  width: 65px !important;*/
/*}*/

/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-8.makeStyles-drawerPaperClose-9.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {*/
/*  width: 65px;*/
/*}*/
/*button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-5.MuiIconButton-colorInherit.MuiIconButton-edgeStart{*/
/*  padding-left: 4px !important;*/
/*}*/

/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-222.makeStyles-drawerPaperClose-223.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{*/
/*  width: 65px;*/
/*}*/

/*button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-219.MuiIconButton-colorInherit.MuiIconButton-edgeStart{*/
/*  padding-left: 0 !important;*/
/*}*/

/*div.MuiPaper-root.MuiDrawer-paper.makeStyles-drawerPaper-203.makeStyles-drawerPaperClose-204.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0{*/
/*  width: 65px;*/
/*}*/

/*button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-200.MuiIconButton-colorInherit.MuiIconButton-edgeStart{*/
/*  padding-left: 0 !important;*/
/*}*/

/*breadcrumb*/
a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorInherit {
    text-decoration: none;
    color: #8BC83F;
}

/*button*/
button.MuiButtonBase-root.MuiIconButton-root.makeStyles-menuButton-5.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
    outline: none;
    padding-left: 9px;
}
.topNavIcon.MuiButtonBase-root{
    padding: 9px 0 9px 9px !important;
}

/*Add user dialogue*/
div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    border-radius: 4px;
}

select {
    width: 100%;
    color: #545454;
    padding: 8px 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input#fullName.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 15px 14px !important;
}

input#emailPhone.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 15px 14px !important;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiPaper-elevation24.MuiPaper-rounded {
    border-radius: 4px;
    /*height: 312px;*/
    /*width: 450px;*/
}

select.custom-select {
    height: 40px !important;
    background: #61dafb;
}

div.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    padding: 0.3rem;
    box-shadow: 1px 1px 3px #bebebe !important;
}

/*a.ui.label {*/
/*    background: white;*/
/*    padding: 1rem !important;*/
/*    margin-left: 30rem !important;*/
/*    width: 300px;*/
/*    !*visibility: hidden;*!*/
/*}*/

input.search {
    position: absolute !important;
}

div#dropdown.ui.active.visible.fluid.multiple.search.selection.dropdown :active {
    outline: none !important;
}

/*div#draggable-dialog-title.MuiDialogTitle-root {*/
/*    padding-bottom: 0 !important;*/
/*    font-size: 22px;*/
/*}*/

div.default.text {
    color: #545454 !important;
}


/*steper*/
.MuiSvgIcon-root {
    fill: #8bc83f;
    font-size: 30px !important;
}

.WithStyles\(ForwardRef\(StepConnector\)\)-completed-225 .WithStyles\(ForwardRef\(StepConnector\)\)-line-226 {
    border-color: #8bc83f !important;
}

.WithStyles\(ForwardRef\(StepConnector\)\)-active-224 .WithStyles\(ForwardRef\(StepConnector\)\)-line-226 {
    border-color: #8bc83f !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #8bc83f !important;
    font-size: 30px !important;
    border: none !important;
}

.MuiStepIcon-root {
    color: #fff !important;
    border: 3px solid #eaeaf0 !important;
    border-radius: 50%;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #fff !important;
    border-radius: 50%;
    border: 3px solid #8bc83f !important;
}

.MuiStepIcon-text {
    fill: #8bc83f !important;
    font-size: 15px !important;
}

.MuiStepConnector-lineHorizontal {
    border-top-style: solid;
    margin-top: 3px !important;
}

.MuiStepLabel-label {
    font-weight: bold !important;
    color: #a6a1a1 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
    color: #8bc83f !important;
    font-weight: bold !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
    color: #8bc83f !important;
}

/*icons*/
svg.MuiSvgIcon-root {
    font-size: 23px !important;
    outline: none;
}

/*sticky table header*/
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall.MuiTableCell-stickyHeader {
    background: white;
}

:focus {
    /*outline: 1px solid #8bc83f5e !important;*/
    outline: none !important;
}


input:focus,
input:active{
    border: 1px solid #8bc83f !important;
}

/*for datepicker in register case*/
.unstyled::-webkit-inner-spin-button{
    margin-top: 10px;
}
.unstyled::-webkit-calendar-picker-indicator {
    /*display: none;*/
    /*-webkit-appearance: none;*/
    margin-right: 10px;

}

/*popup showing actions or workflows*/
div.ui.right.center.popup.transition.visible{
    max-width: inherit;
}

/*multiple search dropdown*/
div.ui.fluid.multiple.search.selection.dropdown{
    min-height: 44px;
    padding-top: 7px;
}

/*expansion panel*/
div.MuiButtonBase-root.MuiExpansionPanelSummary-root{
    min-height: 42px;
}

div.MuiExpansionPanelDetails-root{
    padding: 10px 24px 10px
}

div.MuiInputBase-root.makeStyles-inputRoot-239{
    width:inherit;
}

.ui.multiple.search.dropdown>input.search {
line-height: 36px;
    width:0
}

.styled::-webkit-inner-spin-button{
    display:none
}

.nav-link::-webkit-inner-spin-button{
    display:none
}

i.dropdown.icon{
    padding-top: 15px !important;
}

.ui.fluid.dropdown>.dropdown.icon {
    padding-top: 15px !important;
}

input.MuiInputBase-input{
    /*height: 27px !important;*/
    height: 20px !important;
    padding-left: 2.3rem !important;
}

.ui.search.dropdown.active>input.search, .ui.search.dropdown.visible>input.search{
    height:44px;
}

.ui.search.selection.dropdown>input.search {
    height:44px;
}

.ui.dropdown>.clear.icon:before {
    content: "\f00d";
    color: #636363;
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #8bc83f !important;
}
.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: #8de4870d !important;
}
.MuiIconButton-colorSecondary:hover {
    background-color: #8de4870d !important;
}

img.dataImage{
    width:115px !important;
}
/*https://storage.googleapis.com/treeleaf/micrenc.ttf*/

/*.MuiListItem-button:active{*/
/*    border-right: 2px solid red;*/
/*}*/

/* for isDefault address checkbox  */
.ant-checkbox {
    top: 0.3em !important;
}



