/******HEADER******/

/*breadcrumb*/

.breadcrumb {
  font-size: 14px;
  border-bottom: 1px solid #dddddd;
  padding: 12px 0 12px 2.5%;
  color: #8bc83f;
  font-weight: bold;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 1;
}

.bc {
  font-size: 14px !important;
  border-bottom: 1px solid #dddddd !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 2.5% !important;
  color: #8bc83f !important;
  font-weight: bold !important;
  position: fixed !important;
  width: 100% !important;
  background: white !important;
  z-index: 10 !important;
}

/*link style*/

.link {
  color: #8bc83f;
  font-weight: 600 !important;
}

.links {
  color: #8bc83f !important;
}

.actionsLink,
.actionsLink:hover {
  color: black;
}

.generate-link {
  display: flex;
  justify-content: flex-end;
  color: #8bc83f;
  font-size: 21px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

/*container style*/

.contain {
  margin: 48px 3% 1.5% 3%;
}

.containM {
  margin: 82px 3% 3%;
}

.containerClient {
  background: #ffffff;
  margin-top: 38px;
  margin-left: 3%;
  margin-right: 3%;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.19) 0px 1px 4px 0px;
  position: inherit;
}

.containerPayment {
  background: #ffffff;
  margin-top: 100px;
  margin-left: 28%;
  margin-right: 12%;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.19) 0px 1px 4px 0px;
  position: inherit;
  width: 772px;
}

.dynamicContainer {
  background: #ffffff;
  margin: 0 19px;
}

.table-title {
  color: #7f7d7d;
  padding-bottom: 12px;
  font-size: 16px;
}

/*card style*/

.car {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.19) 0px 1px 4px 0px;
  margin: 0 0 1rem 0;
}

.card-box {
  background-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.19) 0px 1px 4px 0px !important;
  margin-right: 0 !important;
  margin-bottom: 1rem !important;
}

.flex {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  padding-left: 1rem !important;
}

.div-flex {
  padding-bottom: 1rem;
  /* padding-top: 1rem; */
  padding-left: 1rem;
}

/*progress style*/

.progressBar {
  margin-top: 0px;
  height: 0.6rem;
}

.progressData {
  margin-bottom: 0;
  padding-bottom: 0;
}

.progressDiv {
  padding-top: 8px;
  width: 100%;
}

/*extra styles*/

.avatarName {
  font-size: 12px;
  padding: 0.8rem;
  color: #626262;
  margin-left: 2rem;
}

.caseContact {
  margin-top: 12px;
  font-size: 18px;
  color: rgb(139 200 63);
  text-decoration: underline;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 56px;
  right: 30px;
  color: rgb(200, 197, 197);
  font-size: 32px;
}

.view-documents {
  font-size: 20px;
  margin-top: 10px !important;
  color: gray;
  float: right;
  cursor: pointer;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #8bc34a !important;
}

/******PAGE NOT FOUND STYLE******/

.page-not-found {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-not-found .top-span,
.page-not-found .bottom-span {
  color: #8bc83f;
}

.page-not-found .top-span {
  font-size: 32px;
}

.page-not-found .bottom-span {
  font-size: 24px;
}

.page-not-found .inner-span {
  font-size: 32px;
  padding: 0px 12px;
  color: #999595;
}

/******BUTTONS STYLE******/

.settingButton {
  border: none !important;
  background: #8bc83f !important;
  color: white !important;
  padding: 4px !important;
  font-size: 16px !important;
  border-radius: 2px !important;
  margin-left: 1rem !important;
  width: 35px !important;
  height: 35px !important;
}

.addButton {
  border: none !important;
  background: #8bc83f !important;
  color: white !important;
  /*width:  270px ;*/
  height: 35px !important;
  padding: 3px 14px 3px 10px !important;
  font-size: 16px !important;
  border-radius: 2px !important;
  text-transform: capitalize !important;
}

.disabledAddButton {
  background: #b1d681 !important;
  border: 1px solid #b1d681;
  color: white !important;
  height: 35px !important;
  padding: 3px 14px 3px 10px !important;
  font-size: 16px !important;
  border-radius: 2px !important;
  text-transform: capitalize !important;
}

/*.filterButton{*/

/*    border: none;*/

/*    background: #8BC83F;*/

/*    color: white;*/

/*    padding: 4px;*/

/*    font-size: 16px;*/

/*    border-radius: 2px;*/

/*    width: 35px;*/

/*    height: 35px;*/

/*    margin-right: 12px;*/

/*}*/

.deleteClientButton {
  background: #8bc83f !important;
  color: white !important;
  width: 100% !important;
  padding: 0.6rem !important;
}

.settingButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  margin-left: 1rem;
  width: 35px;
  height: 35px;
}

.deleteCaseTemplateButton {
  background: #8bc83f;
  color: white;
  width: 100%;
  padding: 0.5rem;
}

.cancelButton {
  border: 1px solid #8bc83f !important;
  color: #8bc83f !important;
  width: 100% !important;
  padding: 0.5rem !important;
}

.newButton {
  background: #fff;
  border: 1px solid #8080808a;
  padding: 8px;
  border-radius: 12px;
  margin-top: 4px;
}

.listHover:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.linkButton {
  background: rgb(139, 200, 63);
  color: white;
  border: none;
  width: 40%;
  font-size: 17px;
  padding: 0.4rem;
  border-radius: 2px;
  float: right;
  margin-right: -28px;
}

/*.filterButton {*/

/*    border: none;*/

/*    background: #8BC83F;*/

/*    color: white;*/

/*    padding: 4px;*/

/*    font-size: 16px;*/

/*    border-radius: 2px;*/

/*    width: 35px;*/

/*    height: 35px;*/

/*    margin-right: 12px;*/

/*}*/

.nextClick {
  position: absolute;
  left: 81%;
  top: 83%;
  cursor: pointer;
  border: 1px solid #8bc83f;
  background: #8bc83f;
  color: #fff;
  padding: 5px;
  width: 88px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  font-size: 17px;
}

.disableNextButton {
  position: absolute;
  left: 84%;
  cursor: pointer;
  color: #fff;
  padding: 5px;
  width: 88px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  font-size: 17px;
  background: #8bc34a;
  border: 1px solid #8bc34a;
  top: 90%;
}

.printButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 100px;
  height: 35px;
  margin-right: 12px;
  cursor: pointer;
}

.printButton-padding {
  padding-left: 10px;
  padding-top: 5px;
}

.disablePrintButton {
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 2px;
  width: 100px;
  height: 35px;
  margin-right: 12px;
}

.bulkPrintButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 100px;
  height: 35px;
  margin-top: 32px;
}

.nextButton {
  background-color: #8bc83f;
  float: right;
  width: 40%;
  height: 40px;
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  margin-top: 16px;
  margin-right: 0;
  margin-bottom: 5px;
}

.addUserDisableButton {
  float: right;
  background: #b1d681;
  width: 40%;
  height: 40px;
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  margin-top: 16px;
  margin-right: 0;
  margin-bottom: 5px;
}

.submitButton {
  background: #8bc83f !important;
  color: white !important;
  width: 40% !important;
  padding: 0.5rem !important;
  border: 1px solid #8bc83f !important;
  float: right;
}

/******TABLE STYLE******/

.tableBody {
  font-size: 14px !important;
  color: #626262 !important;
  text-align: left !important;
  height: 4.5rem !important;
}

.tableHeader {
  color: #8bc83f !important;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: left !important;
  height: 3rem !important;
  /* top: 0 !important; */
  /* position: inherit !important; */
}

.fixed-table-header {
  height: 66vh !important;
}

.fixed-table-header-second {
  height: 65vh !important;
}

.MuiTableCell-stickyHeader {
  left: auto !important;
}

.first-tableHeader {
  color: #8bc83f !important;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: left !important;
  height: 3rem !important;
  /* top: 0 !important; */
  /* position: inherit !important; */
  padding-left: 40px !important;
}

.tableHeaderAmount {
  color: #8bc83f !important;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: right !important;
  height: 3rem !important;
  /* top: 0 !important; */
  /* position: inherit !important; */
  padding-right: 48px !important;
}

.tableHeaderRefId {
  color: #8bc83f !important;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: left !important;
  height: 3rem !important;
  /* top: 0 !important; */
  /* position: inherit !important; */
  padding-left: 47px !important;
  padding-right: 0 !important;
}

.first-tableBody {
  font-size: 14px !important;
  color: #626262 !important;
  text-align: left !important;
  height: 4.5rem !important;
  padding-left: 40px !important;
  /*text-transform: capitalize !important;*/
}

.cp {
  cursor: pointer;
}

.tableAmountBody {
  text-align: right !important;
  padding-right: 52px !important;
  height: 4.5rem !important;
  font-size: 14px !important;
  color: #626262 !important;
}

.tableRefIdBody {
  font-size: 15px !important;
  color: #626262 !important;
  text-align: left !important;
  height: 4.5rem !important;
  padding-left: 47px !important;
  padding-right: 0 !important;
}

.tableButton {
  border: none !important;
  background: none !important;
  color: #626262 !important;
  padding: 0 !important;
  cursor: pointer !important;
  text-align: left !important;
}

/******INPUT FIELDS STYLE******/

.inputField {
  border: 0;
  background-color: #f4f5f9;
  color: rgb(130, 132, 129);
  font-size: 14px;
  height: 40px;
  width: 100%;
  padding-top: 0px;
  /*margin-top: 16px;*/
  margin-top: 4px;
  padding-left: 15px;
}

.fileNameInput {
  height: 40px;
  background-color: #f4f5f9;
  border: none;
  width: 100%;
  position: inherit;
  margin-bottom: 0.5rem;
  color: #606060;
  padding-left: 10px;
  margin-top: 0.5rem;
  padding-top: 6px;
}

.inputBase {
  width: 270px;
}

.columnHeading {
  color: #625f5f;
  margin-bottom: 0px;
  font-size: 16px;
  width: 100%;
  text-align: left !important;
}

.clientData {
  color: #625f5f;
  margin-bottom: 0px;
  font-size: 16px;
  width: 100%;
  text-align: left !important;
  margin-top: 10px;
  font-weight: 550;
}

.columnHeadingContactPhone,
.columnHeadingContactEmail,
.columnHeadingContactAddress,
.columnHeadingContactType,
.columnHeadingContactDob,
.columnHeadingContactName {
  color: #625f5f;
  font-size: 16px;
  width: 100%;
  margin-bottom: 0;
}

.columnHeadingContactEmail {
  margin-left: -8px;
}

.columnHeadingContactType {
  margin-left: -23px;
}

.columnHeadingContactPhone {
  margin-left: -35px;
}

.columnHeadingContactAddress {
  margin-left: -49px;
}

.columnHeadingContactDob {
  margin-left: -63px;
}

.inputFieldContactEmail,
.inputFieldContactPhone,
.inputFieldContactAddress,
.inputFieldContactDob,
.inputFieldContactName {
  border: 0;
  background-color: #f4f5f9;
  color: rgb(130, 132, 129);
  font-size: 14px;
  height: 40px;
  /*width: 90%;*/
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 13px;
}

.inputFieldContactName {
  width: 90%;
}

.inputFieldContactEmail {
  width: 90%;
  margin-left: -12px;
}

.inputFieldContactType {
  padding-left: 8px;
  height: 40px;
  color: #606060;
  background-color: #f4f5f9;
  border: none;
  width: 89%;
  font-size: 15px;
  margin-left: -23px;
}

.inputFieldContactPhone {
  width: 90%;
  margin-left: -35px;
}

.inputFieldContactAddress {
  width: 95%;
  margin-left: -49px;
}

.inputFieldContactDob {
  width: 221%;
  margin-left: -63px;
}

/******DIALOG*******/

.addUserButton {
  background: #8bc83f !important;
  color: white !important;
  width: 100% !important;
  padding: 0.5rem !important;
  border: 1px solid #8bc83f !important;
}

.addFeeButton {
  background: #8bc83f !important;
  color: white !important;
  width: 111px !important;
  padding: 0.5rem !important;
  border: 1px solid #8bc83f !important;
  float: right !important;
}

.addCompanyButton {
  background: #8bc83f !important;
  color: white !important;
  width: 122px !important;
  padding: 0.5rem !important;
  border: 1px solid #8bc83f !important;
}

.cancelCompanyBtn {
  border: 1px solid #8bc83f !important;
  color: #8bc83f !important;
  width: 122px !important;
  padding: 0.5rem !important;
  margin-right: 12px !important;
}

.dialogHeaderForAddUser {
  cursor: move !important;
  background: #8bc83f !important;
  color: white !important;
  padding-top: 0px !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.dialogContent {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 450px !important;
  /*max-height: 500px !important;*/
}

.dialogContentForLongModal {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  max-width: 1890px !important;
  /*max-height: 500px !important;*/
}

.dialogContentRegister {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 60% !important;
}

.dialogContentBig {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 500px !important;
}

.dialogContentForCompany {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 1080px !important;
  /*width: 900px !important;*/
}

.dialogContentWidth850 {
  background: white;
  border-bottom: none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 850px;
  max-height: 585px;
}

.printShippingDialogContent {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
  width: 635px !important;
}

.dialogContentForForms {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 24px;
  width: 900px;
  /*max-width: 900px;*/
}

.dialogContentForViewPreview {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 30px !important;
  max-width: 900px;
}

.dialogContentForDeleteModal {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  text-align: center;
  width: 450px;
}

.deleteDialogueContent {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  text-align: center;
  width: 450px;
}

.upperTitle {
  margin-top: 12px;
  font-size: 18px;
  color: #696868;
  font-weight: 600;
}

.label {
  color: #606060 !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  width: 100% !important;
  margin-top: 15px !important;
  text-align: left !important;
}

.input {
  border: 0 !important;
  background-color: #f4f5f9 !important;
  color: rgb(130, 132, 129) !important;
  font-size: 14px !important;
  height: 40px !important;
  width: 100% !important;
  padding-top: 0px !important;
  margin-top: 4px !important;
  margin-bottom: 12px !important;
  padding-left: 15px !important;
}

.rangeBase {
  background-color: #f4f5f9 !important;
  color: rgb(130, 132, 129) !important;
  width: 270px !important;
  border: none !important;
  border-radius: 0px !important;
}

.dateBase {
  background-color: #f4f5f9 !important;
  color: rgb(130, 132, 129) !important;
  width: 100% !important;
  border: none !important;
}

.modal-div {
  border: 1px solid #8080804f;
  padding: 15px 35px;
  cursor: pointer;
}

.cancelButton {
  border: 1px solid #8bc83f !important;
  color: #8bc83f !important;
  width: 100% !important;
  padding: 0.5rem !important;
}

.deleteOkButton {
  background: #8bc83f !important;
  color: white !important;
  width: 100% !important;
  padding: 0.5rem !important;
}

.suggestion-date {
  background: #f4f5f9;
  border: none;
  padding: 4px;
  width: 180px;
  color: gray;
}

.disabledButton {
  height: 35px;
  background: #b1d681;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  width: 100px;
}

.extraData {
  color: #625f5f;
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left !important;
  margin-top: 10px;
  font-weight: 550;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/******SEARCH FIELD STYLES******/

.search {
  position: relative;
  border-radius: 0px !important;
  background-color: #f4f5f9;
  margin-right: 1.2rem;
  width: 270px;
  height: 36px !important;
}

.sm-search {
  background-color: #f4f5f9;
  width: 170px;
  height: 30px !important;
  border-radius: 20px !important;
}

.searchIcon {
  width: 7px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem !important;
  margin-left: 1rem;
}

.searchDate {
  position: relative;
  background-color: #f4f5f9;
  margin-right: 1.2rem;
  margin-top: 10px;
  width: 180px;
  height: 40px;
}

.inputBase {
  width: 270px;
  border: none;
}

.sm-search .inputBase {
  width: 170px;
}
.dropdown .ant-select-selector {
  border-radius: 20px !important;
  width: 170px;
  height: 25px !important;
}
.dropdown .ant-select-selector .ant-select-selection-item {
  line-height: 17px !important;
}
/* .sm-search select {
  align-items: center;
} */

.sm-search .MuiInputBase-input:focus {
  border: none !important;
}

/* .ant-popover-placement-bottom {
  min-width: 670px;
  left: 24.33px !important;
} */

/* .ant-popover-placement-left {
  top: 72px !important;
  left: 452px !important;
  min-width: 500px;
} */

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}

.log-popover-title {
  color: #8bc34a;
  font-size: 16px;
}

.log-popover-data-padding {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
}

.log-popover-data {
  color: "#676767";
  font-weight: 600;
}

/******ANT STYLES******/

.ant-tabs-tab:hover,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #8ec944 !important;
}

.ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-tabs-ink-bar {
  background: #aacd5b !important;
}

/* .fixed-tab .ant-tabs-nav { */

/* position: fixed !important; */

/* padding-left: 1rem; */

/* width: 91.3% !important; */

/* background-color: white; */

/* z-index: 10 !important; */

/* right: 2.5% !important; */

/* left: 7% !important; */

/* } */

.tabClass .ant-tabs-nav-list {
  transform: translate(58px, 0px) !important;
}

.ant-tag-blue {
  border-color: #e6f7ff !important;
}

.ant-tag-orange {
  border-color: #fff7e6 !important;
}

.ant-tag-red {
  border-color: #fff1f0 !important;
}

.ant-tag-purple {
  border-color: #f9f0ff !important;
}

.ant-tag-green {
  border-color: #f6ffed !important;
}

.ant-tag-geekblue {
  border-color: #f0f5ff !important;
}

.ant-tag-volcano {
  border-color: #fff2e8 !important;
}

.ant-tag-cyan {
  border-color: #e6fffb !important;
}

.ant-tag-yellow {
  border-color: #feffe6 !important;
}

.ant-tag-lime {
  border-color: #fcffe6 !important;
}

.ant-tag-geekblue {
  border-color: #f0f5ff !important;
}

.ant-tag-gold {
  border-color: #fffbe6 !important;
}

.ant-tag-magenta {
  border-color: #fff0f6 !important;
}

/* .ant-popover-inner-content {
  overflow: auto;
  max-height: 230px;
} */

.ant-select {
  width: 100% !important;
}

.ant-select-selector {
  border: 0px !important;
  background-color: rgb(244, 245, 249) !important;
  color: rgb(130, 132, 129) !important;
  /*height: 40px !important;*/
  width: 100% !important;
  padding-top: 5px !important;
  /*margin-top: 16px !important;*/
  margin-top: 4px !important;
  padding-left: 15px !important;
}

.h40 .ant-select-selector {
  border: 0px !important;
  background-color: rgb(244, 245, 249) !important;
  color: rgb(130, 132, 129) !important;
  height: 40px !important;
  width: 100% !important;
  padding-top: 5px !important;
  margin-top: 4px !important;
  padding-left: 15px !important;
}

.ant-select-arrow {
  top: 36% !important;
}

.ant-select-selector:focus {
  outline: 2px solid #8bc83f !important;
}

.ant-select-arrow {
  padding-top: 8px !important;
}

.ant-select-selection-search-input {
  padding-top: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-radio-inner {
  border-color: #8bc34a !important;
}

.ant-radio-inner::after {
  background-color: #8bc34a !important;
}

/******SELECT STYLES******/

.select-date-full input:focus,
.select-date-full input:active,
.select-date-of-birth input:focus,
.select-date-of-birth input:active,
.select-contact-date-of-birth input:focus,
.select-contact-date-of-birth input:active,
.select-modal-date input:focus,
.select-modal-date input:active,
.select-input-date input:focus,
.select-input-date input:active,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus,
.ant-tabs-tab:focus,
.select-edit-date input:active,
.select-edit-date input:focus,
.select-add-date input:active,
.select-add-date input:focus,
.select-add-date-contact input:focus,
.select-add-date-contact input:active,
.select-dob input:focus,
.select-dob input:active,
.select-input-date input:active,
.select-input-date input:focus,
.select-add-date-filter input:active,
.select-add-date-filter input:focus,
.multiple-select input:active,
.multiple-select input:focus {
  outline: none !important;
  border: none !important;
}

.select-input-date .ant-picker,
.select-add-date .ant-picker,
.select-edit-date .ant-picker,
.select-add-date-contact .ant-picker,
.select-dob .ant-picker,
.select-date-full .ant-picker,
.select-add-date-filter .ant-picker {
  margin-top: 4px !important;
  background: #f4f5f9 !important;
  border-color: #f4f5f9 !important;
  height: 40px !important;
}

.select-dob .ant-picker {
  width: 100% !important;
  margin-top: 5px !important;
}

.select-add-date-contact .ant-picker {
  width: 225% !important;
  margin-left: -64px !important;
  margin-top: 10px !important;
}

.select-add-date .ant-picker {
  width: 100% !important;
}

.select-add-date-filter .ant-picker {
  width: 95% !important;
}

.select-input-date .ant-picker,
.select-edit-date .ant-picker {
  width: 90% !important;
}

.select-contact-date-of-birth .ant-picker {
  width: 390px !important;
  height: 40px;
}

.select-date-of-birth .ant-picker {
  width: 213px !important;
  height: 40px;
}

.select-date-full .ant-picker {
  width: 100% !important;
}

.select-modal-date .ant-picker {
  margin-top: 4px;
  height: 40px;
}

.ant-select-auto-complete input:focus,
.ant-select-auto-complete input:active {
  border: none !important;
}

.select-input-contact input:focus,
.select-input-contact input:active,
.select-input input:focus,
.select-input input:active {
  border: none !important;
}

.select-input .ant-picker-range {
  height: 40px !important;
}

.select-input .ant-picker-focused {
  border-color: #8bc83f !important;
  box-shadow: none !important;
}

.select-input .ant-picker-range .ant-picker-active-bar {
  background-color: #8bc83f !important;
}

.select-input .ant-picker {
  /*width: 170px !important;*/
  width: 100% !important;
}

.select-input .ant-picker-input {
  padding-top: 5px !important;
}

.select-input .ant-picker:hover,
.select-input .ant-picker:focus,
.select-input .ant-picker:active {
  border-color: #8bc83f !important;
}

.select-input-contact .ant-select-selector {
  border: 0px !important;
  background-color: rgb(244, 245, 249) !important;
  color: rgb(130, 132, 129) !important;
  height: 40px !important;
  width: 97% !important;
  padding-top: 5px !important;
  margin-top: 9px !important;
  padding-left: 15px !important;
}

.select-input-dropdown {
  min-height: 40px !important;
  max-height: auto;
  width: 100% !important;
  background-color: #f4f5f9;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
}

.multiple-select-card {
  height: auto;
  border: 1px solid rgb(130, 132, 129);
  color: rgb(130, 132, 129);
  padding: 2px;
  margin: 2px 4px;
  border-radius: 4px;
}

.select-all {
  color: rgb(130, 132, 129);
  padding: 2px;
}

.multiple-select .ant-select-selector {
  border: 0px !important;
  background-color: rgb(244, 245, 249) !important;
  color: rgb(130, 132, 129) !important;
  /*height: 0px !important;*/
  width: 100% !important;
  padding-top: 5px !important;
  margin-top: 9px !important;
  padding-left: 15px !important;
}

.multiple-select .ant-select-selection-item {
  background-color: #fbfbfb91 !important;
}

.auto-comp .ant-select-selector {
  border: 0px !important;
  background-color: rgb(244, 245, 249) !important;
  color: rgb(130, 132, 129) !important;
  height: 40px !important;
  /* min-width: 50px !important; */
  padding-top: 5px !important;
  margin-top: 4px !important;
  padding-left: 15px !important;
}

.auto-comp .ant-select-clear {
  margin-top: 0px;
}

.auto-comp-select .ant-select {
  width: 100% !important;
}

.auto-comp-select-contact .ant-select {
  width: 100% !important;
}

.ant-select .ant-select-focused .ant-select-multiple .ant-select-open {
  border: none !important;
}

div#draggable-dialog-title.MuiDialogTitle-root {
  padding-bottom: 16px;
  cursor: move !important;
}

.error-message {
  color: #ff1807;
  margin-top: 8px;
}

.ant-select-auto-complete .ant-select-clear {
  right: 13px;
  top: 15px;
}

.scrollable-div {
  margin-left: 15px;
  overflow-y: scroll;
  max-height: 70vh;
  overflow-x: hidden;
}

/******PRINT SECTION STYLES******/

@media print {
  .non-printable {
    display: none;
  }
  .print-checkbox {
    margin-left: 70%;
  }
  .print-radio-button {
    margin-left: 70%;
    display: inline-flex !important;
  }
  .bg-w {
    background: #ffffff !important;
  }
  .fileNameInput {
    background: #ffffff !important;
    border: 0.5px solid #9e9e9e !important;
  }
  .main-div-contact {
    margin-left: 68%;
    width: 105%;
    padding: 10px;
  }
  .outerFormDiv {
    position: absolute;
    left: 70%;
  }
  .content-div {
    margin-left: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .labelDiv {
    margin-top: 25px;
  }
  .extra {
    /*margin-top: 12px;*/
    margin-left: 315px;
    /*margin-bottom: 12px;*/
  }
  .toShow {
    display: block;
  }
  .labelAction {
    position: absolute !important;
    left: 40% !important;
  }
  .docInput {
    margin-bottom: 20px !important;
  }
  .micrFont {
    font-family: MICR encoding !important;
    font-size: 40px;
  }
  .noPrint {
    display: none;
  }
  .floatDiv {
    display: none;
  }
  .container {
    margin: 0px 0px 0px 0px !important;
  }
  .hrmlBody {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .footer {
    page-break-after: always;
  }
  .h40 .ant-select-selector {
    background: white !important;
    border: 0.5px solid #9e9e9e !important;
  }
  .mb {
    margin-bottom: -50px !important;
  }
  .ant-picker-suffix {
    display: none !important;
  }
  .ant-select-arrow {
    display: none !important;
  }
  /*.fileNameInput{*/
  /*    background: white !important;*/
  /*}*/
  .fileNameWidth {
    width: 94% !important;
    margin-left: -8px !important;
    margin-bottom: 12px !important;
  }
  .select-dob .ant-picker {
    background: white !important;
    border: 0.5px solid #9e9e9e !important;
  }
  .fileClass {
    margin: 40px 0px;
  }
  @page {
    size: auto;
    margin: 0;
  }
}

.floatDiv {
  position: fixed;
  /*bottom:40px;*/
  right: 40px;
  text-align: center;
  /*box-shadow: 2px 2px 3px #999;*/
  border: none;
  background: #9e9e9e;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 65px;
  height: 35px;
  margin-top: 32px;
}

.micrFont {
  font-family: MICR encoding !important;
  font-size: 40px;
}

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.MuiDrawer-docked {
  height: 100vh;
}

.MuiDrawer-docked ::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
  /* make scrollbar transparent */
}

/* .scrollable-div ::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important;
  display: none !important; 
} */

::-webkit-scrollbar {
  width: 8px !important;
  /*display: none !important;*/
}

/*.noteOuterDiv ::-webkit-scrollbar {*/

/*    display: none !important;*/

/*}*/

.drop .ant-select-dropdown {
  top: 45px !important;
}

.ui.selection.active.dropdown .menu {
  max-height: 120px !important;
}

/*.ui.selection.active.dropdown .clientRegister .menu{*/

/*    height: 74px !important;*/

/*}*/

.drop .ant-select-dropdown > div:first-child > div:nth-child(2) {
  max-height: 95px !important;
}

/*FOR DYNAMIC*/

.fieldLabel {
  color: #606060;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 16px;
  padding-bottom: 0rem;
  position: inherit;
  border: none;
}

.set-password-div {
  margin-left: 25px;
  margin-top: 15px;
  text-decoration: underline;
  font-size: 16px;
  color: #8bc34a;
  cursor: pointer;
}

.filterDialog {
  background: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 450px !important;
  padding-top: 0px !important;
}

.tableCol {
  font-size: 15px !important;
  color: #626262 !important;
  text-align: center !important;
  width: 130px !important;
  /*width: 1020px !important;*/
}

/*//DASHBOARD*/

.dashboard-box {
  /*display: none;*/
  display: flex;
  border: 1px solid #fff;
  /*width: 205px;*/
  width: 245px;
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.19) 0px 3px 3px 0px;
  /*height: 82px;*/
  /*max-height: 98px;*/
}

.define-number {
  font-size: 20px;
  font-weight: 600;
}

.define-title {
  color: #838383;
  font-size: 16px;
  /*min-width: 330px;*/
}

.dash-header {
  color: #757575;
  margin: 72px 3% 12px;
  font-size: 17px;
  font-weight: 600;
}

.img-width {
  margin-top: 10px;
  width: 40px;
}

.img-width2 {
  width: 32px;
  margin-top: 16px;
}

.ml-12 {
  margin-left: 12px;
}

.image-div {
  margin-top: 6px;
  margin-left: 12px;
}

/*border-right: 1px solid #8080803d;*/

/*padding-right: 12px;*/

.outer-div {
  border: 1px solid #8080802e;
  width: 492px;
  margin-left: 60px;
  margin-top: 22px;
  height: 300px;
  /*max-height: 382px;*/
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.19) 0 0 4px 0;
}

.total-div {
  /*display: flex;*/
  padding-left: 2.5%;
  margin-top: 80px;
  margin-left: -18px;
}

.line-chart-div {
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.19) 0 4px 4px 1px;
  /*width: 75%;*/
  margin-top: 32px;
  margin-left: 2.5%;
  /*height: 300px;*/
  height: 330px;
  width: 93.5%;
  padding: 25px;
  border-radius: 12px;
}

.bizcharts {
  padding: 0px 82px !important;
}

.pie-div {
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.19) 0px 4px 4px 1px;
  margin-left: 3%;
  margin-top: 32px;
  border-radius: 12px;
  /*width: 920px;*/
}

.line-graph-title {
  color: #000c17 !important;
}

.bizcharts .line-graph-trend-data {
  color: #000c17 !important;
}

.textAreaDiv {
  width: 100%;
  min-height: 170px;
  border: 0px;
  background-color: #f4f5f9 !important;
  color: rgb(130, 132, 129) !important;
  padding-top: 12px;
  padding-left: 12px;
}

/*//EMAIL TEMPLATE*/

.previewStyle {
  float: right;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.iframeForTemplate {
  width: 550px;
  height: 330px;
  overflow: hidden;
  border: none;
}

.iconImage {
  width: 23px;
  /*margin-right: 5px;*/
}

/*/!*******/

.warningMsg {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.onNote {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 600;
  color: #808080d1;
}

.filterButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 35px;
  height: 35px;
  margin-left: 12px;
}

.exportButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 100px;
  height: 35px;
}

.settingButton {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 35px;
  height: 35px;
  margin-left: 12px;
}

.rangeBase {
  background-color: #f4f5f9;
  color: rgb(130, 132, 129);
  width: 270px;
  border: none;
  border-radius: 8px;
}

.searchDate {
  position: relative;
  background-color: #f4f5f9;
  margin-right: 1.2rem;
  margin-top: 10px;
  width: 180px;
  height: 40px;
}

.error {
  color: #f31b0c;
  font-size: 12px;
  /*display: relative;*/
}

/*//note css*/

.noteAddedName {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #8bc34a;
}

.createdAt {
  margin-left: 15px;
  font-size: 13px;
  color: #515151;
}

.noteDiv {
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid rgb(139 195 74 / 21%);
  /*border-right: 1px solid rgb(139 195 74 / 21%);*/
}

.noteDescription {
  margin-left: 45px;
  white-space: pre-wrap;
  max-width: 60%;
  text-align: left;
}

.searchDate {
  position: relative;
  background-color: #f4f5f9;
  margin-right: 1.2rem;
  margin-top: 10px;
  width: 188px;
  height: 40px;
}

/*CHANGE LOG CSS*/

.changeLogOuterDiv {
  margin-bottom: 12px;
  display: flex;
  /*border-bottom: 1px solid #7c7c7c14;*/
  /*border-bottom: 1px solid #7c7c7c29;*/
  padding-bottom: 14px;
}

.changeConfigOuterDiv {
  margin-bottom: 5px;
  display: flex;
  padding-bottom: 14px;
}

.changeLogAvatar {
  width: 40px !important;
  height: 40px !important;
}

.emptyResponse {
  text-align: center;
  font-size: 20px;
  color: gray;
}

/*//antd*/

.ant-input {
  background: #f4f5f9 !important;
  border-color: #f4f5f9 !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #f4f5f9 !important;
  /* border-right-width: 1px !important; */
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-input form input[placeholder] {
  padding-left: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/*//for dynamic fields*/

.addButtonIcon {
  width: 18px;
  height: 22px;
  cursor: pointer;
}

.addFieldOuterDiv {
  cursor: pointer;
  margin-top: 15px;
}

.addFieldSpan {
  font-size: 14px;
  padding-left: 4px;
}

.openFieldTitle {
  width: 100%;
  color: #8bc83f;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  font-weight: 550;
}

.cursorP {
  cursor: pointer;
}

.fieldLabel12 {
  width: 115px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  background: rgba(204, 204, 254, 0.32);
  border: 1px solid #7b7b7b;
  text-align: center;
  padding: 4px;
  cursor: pointer;
}

.backButton {
  background: #8bc83f;
  color: white;
  border: none;
  width: 130%;
  font-size: 15px;
  padding: 0.4rem;
  border-radius: 2px;
  height: 37px;
}

/*//date button*/

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #8bc34a !important;
  border-color: #8bc34a !important;
  background-color: #ffffff !important;
}

.ant-picker-input > input {
  color: rgb(130, 132, 129) !important;
}

.ant-tag {
  height: auto !important;
}

/* pagination */

.paginate-border {
  justify-content: "flex-start";
  width: 100%;
  border-top: 0.1px solid rgba(224, 224, 224, 1);
}

/* drag and drop */

.ant-upload.ant-upload-drag {
  height: 70% !important;
}

.content1 {
  border-bottom: 1px solid rgba(128, 128, 128, 0.22);
  padding-bottom: 6px;
  cursor: pointer;
  color: rgb(103, 103, 103);
}

.content2 {
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  color: rgb(103, 103, 103);
}

.content3 {
  border-top: 1px solid rgba(128, 128, 128, 0.22);
  padding-top: 6px;
  cursor: pointer;
  color: rgb(103, 103, 103);
}

.searchDate {
  position: relative;
  background-color: #f4f5f9;
  margin-right: 1.2rem;
  margin-top: 10px;
  width: 180px;
  height: 40px;
}

.searchIcon {
  width: 7;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.inputBase {
  width: 270px;
}

.rangeBase {
  background-color: #f4f5f9;
  color: rgb(130, 132, 129);
  width: 270px;
  border: none;
  border-radius: 8px;
}

.block-padding {
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

/* .ant-checkbox:hover {
  border-color: #8bc34a !important;
} */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #8bc34a !important;
}

.ant-switch-checked {
  background-color: #8bc34a !important;
}

.dob-datepicker {
  top: 162px !important;
  left: 562px !important;
}

.schedule-datepicker {
  top: 75px !important;
}

.holiday-start-datepicker {
  left: -17.9965px !important;
  top: 42px !important;
  width: 438px !important;
}

.calendar-holiday-start-datepicker {
  left: -22.9965px !important;
  top: 42px !important;
  width: 443px !important;
}

.calendar-wrapper {
  height: 72vh !important;
  overflow-y: auto;
}

.mask-datepicker {
  left: 5px !important;
  top: 186px !important;
}

.client-calendar-holiday-start-datepicker {
  left: -6.9965px !important;
  top: 90px !important;
  width: 443px !important;
}

/* .ant-checkbox .ant-checkbox-checked:hover {
  background-color: #8bc34a !important;
} */

.printIcon {
  width: 24px !important;
  height: 30px !important;
  margin-right: 15px;
  margin-bottom: 4px;
  color: #9e9e9e;
  cursor: pointer;
}

.cancelIcon {
  width: 22px !important;
  height: 30px !important;
  margin-bottom: 4px;
  color: #9e9e9e;
}

.confirmationContentDiv {
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  text-align: center;
  width: 450px;
}

/***** for material ui date picker *****/

.MuiOutlinedInput-adornedEnd {
  margin-top: 4px !important;
  background: #f4f5f9 !important;
  padding-right: 0 !important;
  height: 40px !important;
}

.margin-top .MuiOutlinedInput-adornedEnd {
  margin-top: -10px !important;
}

/*.date-only-width .MuiOutlinedInput-adornedEnd {*/

/*  width: 250px !important;*/

/*}*/

/*.width-262 .MuiOutlinedInput-adornedEnd {*/

/*  width: 262px !important;*/

/*}*/

/*.width-408 .MuiOutlinedInput-adornedEnd {*/

/*  width: 408px !important;*/

/*}*/

.width-100 .MuiOutlinedInput-adornedEnd {
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background: #8bc83f !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: #ffffff !important;
}

.keyboard-picker input.MuiInputBase-input {
  height: 5px !important;
  padding-left: 10px !important;
  border: none !important;
  color: rgb(130, 132, 129);
}

.disableMessage .MuiFormHelperText-root.Mui-error {
  display: none;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.keyboard-picker input:focus,
input:active {
  border: none !important;
}

.PrivateTabIndicator-colorSecondary-40 {
  background-color: #f5f5f5 !important;
}

.MuiPickerDTTabs-tabs {
  background-color: #8bc83f !important;
}

.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: #8bc83f !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #8bc83f !important;
}

.MuiTabs-indicator {
  background-color: #f5f5f5 !important;
}
.show-range-sign {
  padding-left: 10px;
  color: rgb(98, 98, 98);
}
.show-range input {
  padding-left: 0.5rem !important;
}
.expand-more-icon {
  cursor: pointer;
  color: black;
  opacity: 0.2;
}
.button-css {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 165px;
  height: 35px;
  margin-right: 12px;
}

/******** Table *******/

.table-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.19) 0px 1px 4px 0px;
  margin-left: 2px !important;
  margin-right: 2px !important;
  margin-bottom: 1rem;
}

.flexStyle {
  padding-bottom: 1rem;
  /* padding-top: 1rem; */
  padding-left: 1rem;
  outline: none;
  overflow: auto;
  margin-bottom: 5px;
}

.searchIcon {
  width: 7px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /*// marginRight: 2rem !important;*/
  margin-left: 1rem;
}

.inputBase {
  width: 270px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.table-total-title {
  margin-right: 4px;
  font-size: 16px;
}

.table-total-value {
  font-size: 16px;
  font-weight: 600;
}

.transactionBtn {
  border: none;
  background: #8bc83f;
  color: white;
  padding: 4px;
  font-size: 16px;
  border-radius: 2px;
  width: 165px;
  height: 35px;
  margin-right: 12px;
}

.fixed-table-header-second::-webkit-scrollbar,
.fixed-table-header::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

/* Track */

.fixed-table-header-second::-webkit-scrollbar-track,
.fixed-table-header::-webkit-scrollbar-track {
  background: #f8f5f5;
}

/* Handle */

.fixed-table-header-second::-webkit-scrollbar-thumb,
.fixed-table-header::-webkit-scrollbar-thumb {
  background: rgb(236, 235, 235);
  border-radius: 20px;
}

/* Hover */

.fixed-table-header-second::-webkit-scrollbar-thumb:hover,
.fixed-table-header::-webkit-scrollbar-thumb:hover {
  background: rgb(179, 177, 177);
  border-radius: 20px;
}

.override-mui-table,
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #8bc83f !important;
}

/* .search-sticky-header .MuiTableCell-root {
  top: 78px;
  
  z-index: 2;
  position: sticky;
} */

.sticky-search-bar {
  top: 55px;
  /* left: 0; */
  z-index: 10;
  position: sticky;
  background-color: #fff;
}
.sticky-search-bar-second {
  top: 76px;
  /* left: 0; */
  z-index: 10;
  position: sticky;
  background-color: #fff;
}
@media only screen and (max-width: 1400px) {
  .fixed-table-header {
    height: 60vh !important;
  }
  .fixed-table-header-second {
    height: 50vh !important;
  }
}
.active-color {
  color: #8bc83f;
}
.inactive-color {
  color: #c6c6c6;
}
